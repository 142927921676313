import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";

class LightMovingScene extends HTMLElement {
    constructor() {
        super();
        // Creazione della scena, del renderer, della camera, della luce e del modello
        const scene = new THREE.Scene();
        const clock = new THREE.Clock();
        THREE.Cache.enabled = true;

        this.renderer = new THREE.WebGLRenderer({
            antialias: true,
            alpha: true,
        });
        this.renderer.setClearColor(0x000000, 0);

        this.camera = new THREE.PerspectiveCamera(25, 1, 0.1, 1000);

        const _DEBUG = this.getAttribute("debug");
        var loadedModel;
        const src = new URL("/static/models/JUMBO-8-animation.glb", import.meta.url);
        const loader = new GLTFLoader();
        loader.load( 
            src.pathname,
            (gltf) => {
                const model = gltf.scene;
                this.mixer = new THREE.AnimationMixer(model);
                const clips = gltf.animations;
                clips.forEach(clip => {
                    clip.optimize();
                    this.mixer.clipAction(clip).play();
                })
                scene.add(model);
                loadedModel = model;
                loadedModel.traverse((obj) => {
                    if (obj.isMesh) {
                        //Maglietta, Pelle, Capelli
                        if (obj.material.name == 'Pelle' && !obj.userData.changedSkin) {
                            console.log("🔶 " + obj.name, "🎨 " + obj.material.name);
                            //obj.material.color = new THREE.Color("#ff0060");
                            obj.userData.changedSkin = true;
                        }
                    }
                        
                })
                this.animate();
            },
            undefined,
            (error) => {
                console.error(error);
            }
        );

        const light = new THREE.PointLight(0xffffff, 0.5, 50);
        light.position.set(2, 2, 4);

        const directionalLight = new THREE.DirectionalLight(0xffffff, 1.5);
        directionalLight.position.set(0, 0, 5);

        const directionalLight2 = new THREE.DirectionalLight(0x9999ff, 1.5);
        directionalLight2.position.set(-6, 0, 0);
        const ambientLight = new THREE.AmbientLight("#ffffff", 0.1);
        scene.add(ambientLight)

        if (_DEBUG) {
            let sphereGeometry = new THREE.SphereGeometry(0.1, 32, 32);
            let sphereMaterial = new THREE.MeshBasicMaterial({
                color: 0xffffff,
            });
            this.sphere = new THREE.Mesh(sphereGeometry, sphereMaterial);
            this.sphere.position.set(0, 0, 3);
            scene.add(this.sphere);
        }

        // Aggiunta dell'interazione con il mouse
        window.addEventListener("mousemove", (event) => {
            let mouseX =
                (event.clientX / this.parentElement.clientWidth) * 2 - 1;
            let mouseY =
                (-event.clientY / this.parentElement.clientHeight) * 2 + 1.85;

            let vector = new THREE.Vector3(mouseX, mouseY, 0.5);
            vector.unproject(this.camera);
            let direction = vector.sub(this.camera.position).normalize();
            let position = this.camera.position
                .clone()
                .add(direction.multiplyScalar(1.6));
            
            _DEBUG && this.sphere.position.copy(position);
            light.position.copy(position);
            if (loadedModel) {
                loadedModel.rotation.y = Math.PI * 0.075 * position.x;
            }
            // if (this.mixer)
            //     this.mixer.update((event.movementX > 0 ? 1 : -1) * 0.01);
        });

        // Aggiunta della luce alla scena
        scene.add(light);
        scene.add(directionalLight);
        scene.add(directionalLight2);

        // Definizione delle proprietà e degli attributi
        this.width =
            this.getAttribute("width") || this.parentElement.clientWidth || 300;
        this.height =
            this.getAttribute("height") ||
            this.parentElement.clientHeight ||
            500;
        this.attachShadow({ mode: "open" });

        // Aggiunta del renderer al shadow DOM
        this.shadowRoot.appendChild(this.renderer.domElement);

        // Aggiunta delle proprietà e degli attributi al renderer
        this.renderer.setSize(this.width, this.height);
        this.onWindowResize();
        this.camera.position.z = 2.5;
        this.camera.position.y = 0.7;

        this.animate = () => {
            this.mixer.update(clock.getDelta())
            this.renderer.render(scene, this.camera);
            requestAnimationFrame(this.animate);
        };
    }

    onWindowResize = () => {
        this.renderer.setSize(
            this.parentElement.clientWidth,
            this.parentElement.clientHeight
        );
        this.camera.aspect =
            this.parentElement.clientWidth / this.parentElement.clientHeight;
        this.camera.updateProjectionMatrix();
    };

    connectedCallback() {
        // Avvia l'animazione quando il web component viene inserito nel documento
        window.addEventListener("resize", this.onWindowResize);
    }
}

customElements.define("light-moving-scene", LightMovingScene);
